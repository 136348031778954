var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"pt-2 mt-5"},[_vm._m(0),_c('div',{staticClass:"div-center"},[_c('div',{staticClass:"d-block w-50"},[_c('div',{staticClass:"d-flex"},[_c('div',{staticClass:"d-blok w-100"},[_c('b-form-input',{class:{ 'my-2': !_vm.new_password_visibility },attrs:{"state":_vm.validateState('password'),"type":!_vm.new_password_visibility ? 'password' : 'text',"placeholder":"Nueva contraseña","autocomplete":"off","aria-describedby":"input-password-feedback"},model:{value:(_vm.$v.user.password.$model),callback:function ($$v) {_vm.$set(_vm.$v.user.password, "$model", $$v)},expression:"$v.user.password.$model"}}),_c('b-form-invalid-feedback',{attrs:{"id":"input-password-feedback"}},[_c('div',{staticClass:"d-block text-left"},[_vm._v(" La contraseña de estar compuesta al menos por: "),(!/[a-z]/.test(_vm.user.password))?_c('div',{staticClass:"ml-4"},[_vm._v(" - Al menos una letra en minúsculas. ")]):_vm._e(),(!/[A-Z]/.test(_vm.user.password))?_c('div',{staticClass:"ml-4"},[_vm._v(" - Al menos una letra en mayúsculas. ")]):_vm._e(),(!/[0-9]/.test(_vm.user.password))?_c('div',{staticClass:"ml-4"},[_vm._v(" - Al menos un número. ")]):_vm._e(),(!/[.#?!@$%^&*-]/.test(_vm.user.password))?_c('div',{staticClass:"ml-4"},[_vm._v(" - Al menos un simbolo. ")]):_vm._e(),(
                  _vm.$v.user.password.$params.minLength.min >
                  _vm.user.password.length
                )?_c('div',{staticClass:"ml-4"},[_vm._v(" - Debe tener "+_vm._s(_vm.$v.user.password.$params.minLength.min)+" carácteres como mínimo. ")]):_vm._e()])])],1),_c('b-button',{staticClass:"password-visibility",on:{"click":() => (_vm.new_password_visibility = !_vm.new_password_visibility)}},[(_vm.new_password_visibility)?_c('b-icon',{attrs:{"icon":"eye"}}):_c('b-icon',{attrs:{"icon":"eye-slash"}})],1)],1),_c('div',{staticClass:"d-flex"},[_c('div',{staticClass:"d-block w-100"},[_c('b-form-input',{class:{ 'my-2': !_vm.confirm_password_visibility },attrs:{"state":_vm.validateState('confirm_password'),"aria-autocomplete":"off","type":!_vm.confirm_password_visibility ? 'password' : 'text',"placeholder":"Vuelva a escribir la contraseña","aria-describedby":"input-confirm-password-feedback"},model:{value:(_vm.$v.user.confirm_password.$model),callback:function ($$v) {_vm.$set(_vm.$v.user.confirm_password, "$model", $$v)},expression:"$v.user.confirm_password.$model"}}),_c('b-form-invalid-feedback',{attrs:{"id":"input-confirm-password-feedback"}},[_vm._v("Las contraseñas no son iguales. ")])],1),_c('b-button',{staticClass:"password-visibility",on:{"click":() => (_vm.confirm_password_visibility = !_vm.confirm_password_visibility)}},[(_vm.confirm_password_visibility)?_c('b-icon',{attrs:{"icon":"eye"}}):_c('b-icon',{attrs:{"icon":"eye-slash"}})],1)],1),_c('div',{staticClass:"row my-1"},[_c('div',{staticClass:"col",staticStyle:{"text-align":"left"}},[_c('b-button',{staticClass:"mr-1 w-100",attrs:{"size":"sm"},on:{"click":() => {
                _vm.$router.push({ name: 'Welcome' });
              }}},[_vm._v("Cancelar")])],1),_c('div',{staticClass:"col",staticStyle:{"text-align":"right"}},[_c('b-button',{staticClass:"mr-1 w-100",attrs:{"size":"sm","variant":"primary"},on:{"click":_vm.confirm}},[_vm._v("Confimar cambio de contraseña")])],1)])])])])
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"mt-2"},[_c('h1',[_vm._v("Restablecer la contraseña")])])
}]

export { render, staticRenderFns }